<template>
  <div>

    <div  class="aufgabe"  :class='[{aufgabeverschoben: verschieben},{aufgabebestaetigt: bestaetigt}]'>
      <div @click="erledigt()" class="container">
        <div class="row">
          <div class="col-2 center">
            <slot></slot>
            <!--<img v-if="tablettevisible" src="../assets/icons/medikation_white.svg">
            <img v-else src="../assets/icons/medikation_kapsel_white.svg">-->
          </div>
          <div class="col-8 col-center left">
            <p class="pbold fontcolor-white">{{ medicationname }}</p>
            <p class="pregular fontcolor-white opacity40" :class='[{hide: bestaetigt}]'>{{ takinganzahl }}</p>
          </div>
          <div class="col-2 col-right center opacity0" :class='[{show: bestaetigt}]'>
            <img class="deleteimage" src="../assets/icons/done.svg" alt="">
          </div>
        </div>
      </div>
    </div>

    <div @click="loeschen($event)" class="delete" :class='[{hide: bestaetigt}]'>
      <img class="deleteimage" src="../assets/icons/done.svg" alt="">
    </div>

  </div>
</template>

<style>
  .aufgabeverschoben { 
    transition: all 0.4s ease-in-out;
    transform: translateX(-108px);
  }

  .aufgabebestaetigt {
    opacity: 0.5;
    border: solid 1px rgba(255, 255, 555, 0.2);
    transition: all 0.4s ease-in-out;
    transform: translateX(0px);
  }

  .hide {
    display: none;
  }

  .show {
    opacity: 1;
  }
  
</style>

<script>
import { mapActions } from 'vuex';

export default {
  props: ['medicationname', 'takingdose', 'takinganzahl'],
  data(){
        return{ 
            verschieben: false,
            bestaetigt: false,
            tablettevisible: true,
        }
  },
  methods: {
      erledigt(){
        //console.log(event);
        this.verschieben = !this.verschieben;
      },

      loeschen(event){
        console.log(event);

            //this.bestaetigt = true;
            //an datenbank schicken
            console.log(this.medicationname);
            this.setEinnahme(this.medicationname);
            this.setEinnahmeTag({name: this.medicationname, tagesZeit: this.tagesZeit});
      },

      //darstellungsform(){
      //  if((getMedikationinfos.darstellung[tablette] == 1)){
       //   this.tablettevisible == true
       // } else {
       //   this.tablettevisible == false
        //}
      //},
    },
}
</script>


