<template>
  <div>
    
    <div class="header">
      <div class="container">
        <div class="row">
          <div class="col-2 col-right center container">
            <button type="button" @click="showModalprofil">
              <img src="../assets/icons/profil.svg">
            </button>

            <Modal
              v-show="isModalVisibleprofil"
              @close="closeModalprofil"
              modaltitle="Profil Funktion"
              modaltext="Hier können Sie die App zukünftig nach eigenen Wünschen konfigurieren und so individuell an Ihre Bedürfnisse anpassen."
            />
          </div>
          <div class="col-8 col-center-content center">

            <div class="hometab">
              <button class="hometabtoggle" @click="homejahrzuhome()">
                <h4>Heute</h4>
              </button>
              <button class="hometabtoggle activetoggle">
                <h4>Jahr</h4>
              </button>
            </div>

            <!-- <ul class="tabs">
              <li class="hover"></li>
              <li class="tab active" data-tab="heute">Heute</li>
              <li class="tab" data-tab="jahr">Jahr</li>
            </ul> -->
          </div>
          <div class="col-2 col-right center container">
            <button type="button" @click="showModalchat">
              <img src="../assets/icons/chat.svg">
            </button>

            <Modal
              v-show="isModalVisiblechat"
              @close="closeModalchat"
              modaltitle="Chat Funktion"
              modaltext="Hier können Sie sich zukünftig schnell und einfach mit Ihrem Versorgungsteam abstimmen und Informationen austauschen."
            />
          </div>
        </div>
      </div>
    </div>

    <b-modal class="modal-center" id="modal-center" centered>
      <p class="pbold fontcolor-mainblue">Profil Funktion</p>
      <p class="pregular fontcolor-mainblue"> Mit dieser Funktion können Sie sich in Zukunft schnell und unkompliziert mit Ihrem Versorgungsteam abstimmen und Informationen auszutauschen.</p>
    </b-modal>

    <b-modal class="modal-center" id="modal-center-chat" centered>
      <p class="pbold fontcolor-mainblue">Chat Funktion</p>
      <p class="pregular fontcolor-mainblue">  Mit dieser Funktion können Sie sich in Zukunft schnell und unkompliziert mit Ihrem Versorgungsteam abstimmen und Informationen auszutauschen.</p>
    </b-modal> -->

    <div class="paddingtop115">
      <div id="jahr">

        <!-- To-Do List-->
        <div class="paddingbottom110">
          <div class="todolistabschnitt">
            <div class="container todolistueberschrift">
              <p class="pregular fontcolor-white opacity80">Kontrolltermine</p>
            </div>
            <aufgabejahr medicationname="Urologe" takingdose="" takinganzahl="Vorsorge"><p class="pregular fontcolor-white">1x</p></aufgabejahr>
            <aufgabejahr medicationname="Zahnarzt" takingdose="" takinganzahl="Vorsorge"><p class="pregular fontcolor-white">1x</p></aufgabejahr>
            <aufgabejahr medicationname="Hautarzt" takingdose="" takinganzahl="Vorsorge"><p class="pregular fontcolor-white">2x</p></aufgabejahr>
            <aufgabejahr medicationname="Kardiologe" takingdose="" takinganzahl="Vorsorge"><p class="pregular fontcolor-white">1x</p></aufgabejahr>
            <aufgabejahr medicationname="Transplantzentrum" takingdose="" takinganzahl="Vorsorge"><p class="pregular fontcolor-white">2x</p></aufgabejahr>

          </div>
        </div>
      </div>
    </div>
    <foot-navigation></foot-navigation>
  </div>
</template>

<script>
  import FootNavigationVue from '../components/FootNavigation.vue';
  import StartseiteAufgabeJahrVue from '../components/StartseiteAufgabeJahr.vue';
  import Modal from "../components/Modal.vue";
  import router from '../router';

  export default {
    name: 'home',
    components: {
      'foot-navigation' :FootNavigationVue,
      'aufgabejahr' : StartseiteAufgabeJahrVue,
      Modal,
    },
    data() {
      return {
        isModalVisibleprofil: false,
        isModalVisiblechat: false,
        tabList: ["Heute", "Jahr"],
      };
    },
    methods: {
      homezuwerteabfragetemperatur() {
        router.push({path: '/werteabfragetemperatur'})
      },
      homejahrzuhome() {
        router.push({path: '/home'})
      },
      showModalchat() {
        this.isModalVisiblechat = true;
      },
      showModalprofil() {
        this.isModalVisibleprofil = true;
      },
      closeModalchat() {
        this.isModalVisiblechat = false;
      },
      closeModalprofil() {
        this.isModalVisibleprofil = false;
      }
    }
  }
</script>

<style scoped>

</style>